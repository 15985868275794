import React from 'react';
import logo from '../static/images/bruno_logo_horizontal-vetor.png';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Header = () => {
    return(
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>Fotografia Imobiliária</p>
            
            <span className="networks-line" ><WhatsAppIcon />(51) 99273 7872</span>
            <span className="networks-line" ><InstagramIcon /><FacebookIcon /> /brunoperesfotografia </span>
        </header>
    )
}

export default Header;