import React from 'react';
import { SRLWrapper } from "simple-react-lightbox"; // Import SRLWrapper
import LazyLoad from 'react-lazy-load';
import ImageLoader from './ImageLoader.js';

import '../styles/galery.scss';


import image1 from '../static/images/IMG_0103.jpg';
import image2 from '../static/images/IMG_0258.jpg';
import image3 from '../static/images/IMG_0527.jpg';
import image4 from '../static/images/IMG_0543.jpg';
import image5 from '../static/images/IMG_0830.jpg';
import image6 from '../static/images/IMG_0895.jpg';
import image7 from '../static/images/IMG_2580.jpg';
import image8 from '../static/images/IMG_2586.jpg';
import image9 from '../static/images/IMG_3306.jpg';
import image10 from '../static/images/IMG_3308.jpg';
import image11 from '../static/images/IMG_3310.jpg';
import image12 from '../static/images/IMG_4129.jpg';
import image13 from '../static/images/IMG_4131.jpg';
import image14 from '../static/images/IMG_4173.jpg';
import image15 from '../static/images/IMG_4174.jpg';

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15]

const Gallery = (props) => {

    return (
        <LazyLoad 
            debounce={false}
            throttle={250} 
            onContentVisible={() => console.log('what do you want to do here?')}
        >
            <div id="main-gallery">
                <SRLWrapper>
                    {images && images.map((i) => (
                        <a href={i} data-attribute="SRL">
                            <ImageLoader alt="Bruno Peres - Fotografia Imobiliária" src={i} />
                        </a>
                    ))}
                </SRLWrapper>
            </div>
        </LazyLoad>
    )
}

export default Gallery;