import React from 'react';
import SimpleReactLightbox from "simple-react-lightbox";
import Header from './components/Header'
import Gallery from './components/Gallery'
import './App.scss';

function App() {

    return (
        <div className="App">
            <SimpleReactLightbox>
                <Header />
                <main>
                    <Gallery />
                </main>
            </SimpleReactLightbox>
        </div>
    );
}

export default App;
